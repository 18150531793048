import React, { useEffect, useState } from "react";
import { useApplicationContext } from "../../provider";
import { withPrefix } from "gatsby";
import { isLoggedIn } from "../services/auth";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Footer from "../components/footer-1";
import Header from "../components/header";
import ArtContainer from "../components/choose-art/art-container";
import { getArts } from "../services/apiCalls";
import Swal from 'sweetalert2';
import { getUserFromAccessToken } from "../services/auth";
import { useDispatch, useSelector } from 'react-redux';
import { postLedgerHistory } from "../sagas/credits/creditsSlice";
import { upscaleImage, resetUpscaleImage, selectCarouselsState } from "../sagas/carousels/carouselsSlice";

export default function Layout() {
  const dispatch = useDispatch();
  const { isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto } = useSelector(selectCarouselsState);

  const [merchfyText, setMerchfyText] = useState("Merchify It");
  const currentUser = getUserFromAccessToken();
  const [count, setCount] = useState();
  const [showCloseTips, setShowCloseTips] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [imgArray, setImgArray] = useState([]);
  const [imgUrls, setImgUrl] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
      return;
    }

    const generateCount = parseInt(
      currentUser.generateCount
        ? parseFloat(currentUser.generateCount) > parseFloat(currentUser.credits)
          ? parseFloat(currentUser.credits) / 1
          : currentUser.generateCount
        : 0
    );

    if (generateCount < 1) {
      navigate("/pricing-options");
    }

    setCount(generateCount);

    const tempImgArray = new Array(generateCount).fill(
      "assets/img/green-little-balls.gif"
    );
    setImgArray(tempImgArray);

    let tempItemArray = new Array(generateCount).fill(0);
    setSelectedItems(tempItemArray);

    generateArt(generateCount, 0, applicationState.detailedDescription ?? "cyber punk");

    localStorage.setItem("detailedDescription", applicationState.detailedDescription ?? "cyber punk");
    localStorage.setItem("mergify", JSON.stringify([]));
  }, []);

  const { applicationState, setApplicationState, setCreditCount } = useApplicationContext();

  const generateArt = async (generateCount, refreshCount, phrase) => {
    let str = phrase || "cyber punk";
    let jobarr = new Array();
    let imagesArray = [];
    if (refreshCount === 0) {
      imagesArray = new Array(generateCount).fill("assets/img/green-little-balls.gif");
    } else {
      imagesArray = [...imgArray];
      selectedItems.forEach((item, index) => {
        if (item == 0) {
          imagesArray[index] = "assets/img/green-little-balls.gif";
        }
      });
      setImgArray([...imagesArray]);
    }
    let skipIdxes = new Array(selectedItems.length).fill(-1);
    let loopCount = refreshCount == 0 ? generateCount : refreshCount;
    let newImagesUrl = [];
    try {
      const { data: response } = await getArts(str, loopCount);

      if (response !== undefined && response.status != "failed" && !!response.images?.length) {
        response.images.forEach((image, i) => {
          if (refreshCount == 0) {
            imagesArray[i] = image;
          } else {
            for (let j = 0; j < selectedItems.length + 1; j++) {
              if (selectedItems[j] == 0 && skipIdxes[j] == -1) {
                imagesArray[j] = image;
                skipIdxes[j] = 0;
                break;
              }
            }
          }
  
          setImgArray([...imagesArray]);
          //what is this bullshit?Not sure what it does mean.
          jobarr[i] = ',,,,,';
          localStorage.setItem("job_id", jobarr);
          newImagesUrl.push(image);
        });
  
        let user = JSON.parse(localStorage.getItem("currentUser") || "{}");
        user.credits = response.credits;
        localStorage.setItem("currentUser", JSON.stringify(user));
        setCreditCount(response.credits);

        //Save user creations
        setCreditImages((creditImages) => [...creditImages, ...response.images]);
      }
  
      setImgUrl([...imgUrls, ...newImagesUrl]);
    } catch (error) {
      console.log(error);
      //swal popup that says something went wrong and re-try message
      Swal.fire({
        icon: 'error',
        text: "We apologize for any inconvenience caused, but we have noticed that Stability AI's Stable Diffusion technology is currently experiencing an outage. We kindly request that you attempt your request again, or at a later time. Thank you.",
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) generateArt(generateCount, refreshCount, phrase);
      })
    }
  };

  const [creditImages, setCreditImages] = useState([]);
  const sendCreditImages = async () => {
    const payload = {
      images: creditImages,
      prompt: applicationState.detailedDescription ?? "cyber punk",
    };

    dispatch({
      type: postLedgerHistory.type,
      payload,
    });
  };

  async function onMerchifyClick() {
    var conceptName = $(".selected").find(".figure img");

    if (!conceptName.length) {
      Swal.fire({
        icon: 'warning',
        text: 'Please select arts to merchify!',
      })
  
      return;
    }

    sendCreditImages();
    setShowLoading(true);
    localStorage.removeItem("selectedArray");

    const payload = {
      images: conceptName.toArray().map((c) => c.src),
    };

    dispatch({
      type: upscaleImage.type,
      payload,
    });
  }

  useEffect(() => {
    if (isUpscalingImageSuccess) {
      const carouselPageCount = UpscaleImageResponseDto.results.length;
      
      const merchifyArr = UpscaleImageResponseDto.results.map((r, i) => {
        return {
          id: i,
          upscaled: r.upscaled,
          original: r.original,
        }
      });

      setApplicationState({
        ...applicationState,
        carouselPageCount: carouselPageCount,
      });
      localStorage.setItem("mergify", JSON.stringify(merchifyArr));
      
      dispatch(resetUpscaleImage());
      navigate("/select-merch");
    } else if (isUpscalingImageFailed) {
      dispatch(resetUpscaleImage());
      setMerchfyText("Try Again");
      setShowLoading(false);
    }
  }, [isUpscalingImageSuccess, isUpscalingImageFailed, UpscaleImageResponseDto])

  const onRegenerateClick = (e) => {
    e.preventDefault();
    let selectedCount = selectedItems.filter((item, index) => item == 0).length;

    if (applicationState.detailedDescription) {
      generateArt(0, selectedCount, applicationState.detailedDescription);
      localStorage.setItem(
        "detailedDescription",
        applicationState.detailedDescription
      );
    } else {
      generateArt(0, selectedCount, "cyber punk");
      localStorage.setItem("detailedDescription", "cyber punk");
    }
  };

  return (
    <>
      <Helmet>
        <link
          href={withPrefix("assets/css/bootstrap.min.css")}
          rel="stylesheet"
        />
      </Helmet>

      <Header />

      {showLoading && (
        <div className="new_gif" style={{ display: "flex" }}>
          <img
            src={withPrefix("assets/img/green-little-balls.gif")}
            className="img-fluid mw_50"
            alt="loading"
          />
        </div>
      )}

      <main className="content-main">
        <div className="breadcrumb">
          <ul>
            <li><a href="#" onClick={(e) => {e.preventDefault();navigate("/beta");}}>Create</a></li>
            <li><a href="#" onClick={(e) => e.preventDefault()}>Choose Art</a></li>
          </ul>
        </div>

        <div className="feature-list">
          <div className="container">
            <div className="heading-top">
              <h2>Choose Art</h2>
              <div className="image_refresh_div">
                <img
                  className="image_refresh"
                  onClick={onRegenerateClick}
                  src={withPrefix("assets/img/refresh-svgrepo-com.svg")}
                  alt="img/art-2.png"
                />
                <div className="generate_count" style={{ display: "none" }}>
                  {count}
                </div>
              </div>
            </div>
            <div
              className="row"
              id="artContainer"
              style={{ justifyContent: "center" }}
            >
              {Array(count)
                .fill(0)
                .map((item, index) => (
                    <ArtContainer
                      key={index}
                      index={index}
                      image={imgArray[index]}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                ))
              }
            </div>
            {/* <div className="btn-out">
              <p className="disclaimer"  >
                <strong>Tips:</strong> Non-selected images are always available in your profile. NSFW-filtered images are not charged and can be reviewed in the transaction ledger. Click the refresh icon to generate new items, nothing previosly selected images will not be refreshed.
              </p>
            </div> */}

            <div className="btn-out">
              <div className="btn btn-blue" onClick={onMerchifyClick}>
                {merchfyText}
              </div>
            </div>
          </div>
        </div>

        {/* bottom sticky-alert */}
        {showCloseTips && (
          <div className="sticky-alert">
            <div className="aside">
              <p>
                <strong>Tips:</strong> Click the refresh icon to re-generate
                non-selected items.
              </p>
              <div className="more-link">
                <a href="#">more info.</a>
              </div>
              <div className="txt-bx">
                Blurred (NSFW) Generations are not billed and can be reviewed in
                the transaction ledger.
              </div>
              <div onClick={() => setShowCloseTips(false)} className="close-alert">
                <em className="fal fa-times"></em>
              </div>
            </div>
          </div>          
        )}
      </main>

      <Footer />
    </>
  );
}
