import * as React from 'react'
import { useState, useEffect } from "react";
import { withPrefix } from "gatsby";
const ArtContainer = ({ 
    index,
    image,
    selectedItems,
    setSelectedItems,
}) => {

    const onClickItem = (e) => {
        e.preventDefault();
        const temp = [...selectedItems];
        let selectedCount = selectedItems.filter((item, index) => item > 0).length;
        if(temp[index] == 0) {
            temp[index] = selectedCount + 1;
        } else {
            temp[index] = 0;
            for(let i = 0 ; i < temp.length ; i ++) {
                if(temp[i] > selectedItems[index]) {
                    temp[i] --;
                }
            }
        }
        setSelectedItems([...temp])
    }

    return (
        <div className="col-md-4" key={index}>
            <a className={`box art-image ${selectedItems[index] ? selectedItems[index] > 0 && image !== "assets/img/green-little-balls.gif" ? "selected" : "" : ""}`} onClick={onClickItem}>
                <div className="figure">
                    <img className="image_art" id={`image_art${index}`} src={withPrefix(image)} alt={`img/art-${index}.png`} />
                </div>
                <div className="aside">
                    {
                        selectedItems[index] ? selectedItems[index] > 0 ? 
                            <div className="number">{
                                selectedItems[index]
                            }</div>: <></> : <></>
                    }
                </div>
            </a>
        </div>
    )
}

export default ArtContainer;